<template>
  <section
    class="invoice-preview-wrapper"
  >
    <div
      class="invoice-preview d-flex flex-wrap"
    >
      <!-- Col: Left (Invoice Container) -->
      <div
        v-if="showDefaultLayout"
        class="invoice-preview-col"
      >
        <b-card
          v-for="(dispatch, index) in chunkedDispatchNotes"
          id="invoice-preview-card"
          :key="index"
          no-body
          class="invoice-preview-card"
          style="width:930px;"
        >
          <!-- Header -->
          <b-card-body
            class="invoice"
          >
            <b-row
              id="invoiceTableParent"
              class="d-flex flex-wrap"
            >
              <b-col
                v-for="(dispatchNote, index1) in dispatch"
                :id="dispatchNote.id"
                :key="index1"
              >
                <table
                  class="table-border"
                  style="width:430px;"
                >
                  <tr
                    class="table-padding"
                  >
                    <td>
                      <span class="brand-logo">
                        <b-img
                          class="dn-brand-logo"
                          :src="courier.company_logo ? `${bucketUrl}${courier.company_logo}` : defaultCompanyLogo"
                          alt="logo"
                        />
                      </span>
                    </td>
                    <td>
                      <div style="float: right">
                        <h5 class="dn-waybill">
                          Waybill ID : {{ dispatchNote.waybill_number }}
                        </h5>
                        <div
                          style="padding-right:10px;"
                        >
                          <Barcode
                            :id="dispatchNote.waybill_number"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    class="table-padding"
                    height="110px"
                  >
                    <td>
                      <h4
                        class="dn-business-name"
                      >
                        {{ courier.company_name }}
                      </h4>
                      <span class="dn-business-detail"> {{ courier.company_address }} <br> {{ courier.company_phone }} </span>
                    </td>
                    <td>
                      <div style="float: right">
                        <td>
                          <div>
                            <QRcode
                              :id="dispatchNote.waybill_number"
                            />
                          </div>
                        </td>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <table width="100%">
                        <!-- row01 -->
                        <tr
                          class="dn-border-top"
                        >
                          <td
                            class="dn-border-right col-sm-6"
                          >
                            <h4 class="dn-sub-header">
                              Merchant Details
                            </h4>
                          </td>
                          <td class="col-sm-6">
                            <h4 class="dn-sub-header">
                              Customer Details
                            </h4>
                          </td>
                        </tr>
                        <!-- row02 -->
                        <tr
                          class="dn-border-top"
                          height="170px"
                        >
                          <td
                            class="dn-border-right col-sm-6"
                          >
                            <div class="dn-valign">
                              <h5 class="dn-content-header">
                                Name
                              </h5>
                              <span class="dn-content">{{ dispatchNote.merchant_business.business_name }}</span>
                            </div>
                            <div class="dn-valign">
                              <h5 class="dn-content-header">
                                Address
                              </h5>
                              <span class="dn-content">
                                {{ dispatchNote.merchant_business_address ? dispatchNote.merchant_business_address.address_line_1 : '---' }} <br>
                                {{ dispatchNote.merchant_business_address ? dispatchNote.merchant_business_address.address_line_2 : '---' }} <br>
                                {{ dispatchNote.merchant_business_address ? dispatchNote.merchant_business_address.address_line_3 : '---' }}
                              </span>
                            </div>
                            <div class="dn-valign">
                              <h5 class="dn-content-header">
                                Telephone
                              </h5>
                              <span class="dn-content">{{ dispatchNote.merchant_business_address ? dispatchNote.merchant_business_address.phone : '---' }}</span>
                            </div>
                          </td>
                          <td class="col-sm-6">
                            <div class="dn-valign">
                              <h5 class="dn-content-header">
                                Name
                              </h5>
                              <span class="dn-content">{{ dispatchNote.customer_name }}</span>
                            </div>
                            <div class="dn-valign">
                              <h5 class="dn-content-header">
                                Address
                              </h5>
                              <span class="dn-content text-break">{{ dispatchNote.customer_address }}</span>
                            </div>
                            <div class="dn-valign">
                              <h5 class="dn-content-header">
                                Telephone
                              </h5>
                              <span class="dn-content">{{ dispatchNote.concated_phone_number }}</span>
                            </div>
                          </td>
                        </tr>
                        <!-- row03 -->
                        <tr
                          class="dn-border-top"
                        >
                          <td colspan="2">
                            <h4 class="dn-sub-header">
                              Order Details
                            </h4>
                          </td>
                        </tr>
                        <!-- row04 -->
                        <tr class="dn-border-top">
                          <td
                            class="dn-border-right col-sm-6"
                            style="padding-bottom:10px;"
                          >
                            <div style="margin-top:6px">
                              <h5 class="dn-content-header">
                                Order Number
                              </h5>
                              <span class="dn-content">{{ dispatchNote.order_no }}</span>
                            </div>
                            <div>
                              <h5 class="dn-content-header">
                                Order Date
                              </h5>
                              <span class="dn-content">{{ __dateTimeFormatter(dispatchNote.created_at) }}</span>
                            </div>
                            <div>
                              <h5 class="dn-content-header">
                                Postal / Zip Code
                              </h5>
                              <span class="dn-content">{{ dispatchNote.city.postal_code }}</span>
                            </div>
                            <div>
                              <h5 class="dn-content-header">
                                Weight
                              </h5>
                              <span class="dn-content"> {{ dispatchNote.weight }}Kg </span>
                            </div>
                          </td>
                          <td class="d-flex flex-column justify-content-start w-100%">
                            <div style="height:70px;margin-top:6px">
                              <h5 class="dn-content-header">
                                Description
                              </h5>
                              <span class="dn-content">
                                {{ dispatchNote.description? dispatchNote.description.substring(0,55) : 'N/A' }}
                              </span>
                            </div>
                            <div style="margin-top:10px">
                              <h5 class="dn-content-header">
                                City
                              </h5>
                              <span class="dn-content">
                                {{ dispatchNote.city.name }}
                              </span>
                            </div>
                            <div>
                              <h5 class="dn-content-header">
                                Total COD
                              </h5>
                              <span class="dn-content">
                                Rs. {{ dispatchNote.cod }}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr
                          class="dn-border-top"
                        >
                          <td
                            colspan="100%"
                            class="curfox-label font-small-2"
                          >
                            Powered By Curfox.com
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
            <!--        </div>-->
          </b-card-body>
        </b-card>
      </div>
      <div
        v-else
        id="custom-invoice-preview-col"
        class="invoice-preview-col"
      />
      <!-- Right Col: Card -->
      <b-col
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-warning"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>
        </b-card>
      </b-col>
    </div>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BButton, BImg,
} from 'bootstrap-vue'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
import Vue from 'vue'
import { bucketUrl, defaultCompanyLogo } from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import Barcode from '@/components/print/Barcode.vue'
import QRcode from '@/components/print/QRcode.vue'

const OrderRepository = RepositoryFactory.get('order')
const LayoutRepository = RepositoryFactory.get('layout')

export default {
  directives: {
    Ripple,
  },
  components: {
    QRcode,
    Barcode,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BImg,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      courier: {},
      dispatchNotes: [],
      bucketUrl,
      defaultCompanyLogo,
      showDefaultLayout: false,
      waybillLayout: null,
      loading: false,
      waybillsPerCanvas: 4,
    }
  },
  computed: {
    chunkedDispatchNotes() {
      return _.chunk(this.dispatchNotes, this.waybillsPerCanvas)
    },
  },
  async mounted() {
    this.courier = JSON.parse(localStorage.getItem('businessSettings'))
    await this.getOrdersByWaybills()
    await this.getWaybillLayout()
    if (this.waybillLayout) {
      this.initWaybillLayout()
    } else {
      this.showDefaultLayout = true
    }
  },
  methods: {
    async getOrdersByWaybills() {
      try {
        this.loading = true
        const { data } = (await OrderRepository.getPODOrdersByWaybills(this.$route.params.selectedWaybills))
        this.dispatchNotes = data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async printInvoice() {
      window.print()
    },
    async getWaybillLayout() {
      try {
        this.loading = true
        const { data } = (await LayoutRepository.getWaybillLayoutDetail(this.$route.params.waybillId))
        this.waybillLayout = data.data.template
        this.waybillsPerCanvas = data.data.waybills_per_canvas
      } catch (e) {
        this.showErrorMessage('Couldn\'t fetch waybill layout!')
      }
      this.loading = false
    },
    initWaybillLayout() {
      const container = document.getElementById('custom-invoice-preview-col')
      const removals = ['draggable', 'resizable', 'resize-drag', 'resize-drag-vertical', 'resize-drag-horizontal', 'contenteditable']
      removals.forEach(removal => {
        this.waybillLayout = this.waybillLayout.replaceAll(removal, '')
      })
      this.chunkedDispatchNotes.forEach(chunk => {
        const tempElement = document.createElement('div')
        tempElement.classList.add('invoice-preview-card')
        tempElement.style.width = '930px'
        tempElement.innerHTML = this.waybillLayout
        container.appendChild(tempElement)
        const outOfPlaceList = []
        document.getElementById('invoiceTableParent').children.forEach(child => {
          if (child.tagName !== 'TABLE') {
            const copy = child.cloneNode(true)
            outOfPlaceList.push(copy)
          } else {
            document.getElementById('invoiceTableParent').innerHTML = child.outerHTML
          }
        })
        const tableParent = document.getElementById('invoiceTableParent')
        const tableInnerHTML = document.getElementById('invoiceTableParent').innerHTML
        document.getElementById('invoiceTable').remove()
        document.querySelectorAll('.customComponent').forEach(element => {
          element.remove()
        })
        if (document.querySelector('#barcode_CF000000')) {
          document.querySelector('#barcode_CF000000').remove()
        }
        if (document.querySelector('#qrcode_CF000000')) {
          document.querySelector('#qrcode_CF000000').remove()
        }
        chunk.map(async dispatchNote => {
          const tableToModify = tableInnerHTML
          const invoiceElement = document.createElement('div')
          invoiceElement.classList.add('col')
          invoiceElement.innerHTML = tableToModify
          tableParent.appendChild(invoiceElement)
          const newTable = document.getElementById('invoiceTable')
          // eslint-disable-next-line array-callback-return
          outOfPlaceList.map(child => {
            newTable.insertAdjacentElement('afterbegin', child)
          })
          newTable.id = `id${(new Date()).getTime()}`
          newTable.classList.add('invoiceTable')
          tableParent.id = `id${(new Date()).getTime()}`
          const mappers = [
            {
              key: '-- business_name --',
              value: dispatchNote.merchant_business.business_name || 'N/A',
            },
            {
              key: '-- address_line_1 --',
              value: dispatchNote.merchant_business_address ? dispatchNote.merchant_business_address.address_line_1 : '---',
            },
            {
              key: '-- address_line_2 --',
              value: dispatchNote.merchant_business_address ? dispatchNote.merchant_business_address.address_line_2 : '---',
            },
            {
              key: '-- address_line_3 --',
              value: dispatchNote.merchant_business_address ? dispatchNote.merchant_business_address.address_line_3 : '---',
            },
            {
              key: '-- phone --',
              value: dispatchNote.merchant_business_address ? dispatchNote.merchant_business_address.phone : '---',
            },
            {
              key: '-- customer_name --',
              value: dispatchNote.customer_name || 'N/A',
            },
            {
              key: '-- customer_address --',
              value: dispatchNote.customer_address || 'N/A',
            },
            {
              key: '-- concated_phone_number --',
              value: dispatchNote.concated_phone_number || 'N/A',
            },
            {
              key: '-- customer_secondary_phone --',
              value: dispatchNote.customer_secondary_phone || '',
            },
            {
              key: '-- waybill_number --',
              value: dispatchNote.waybill_number || 'N/A',
            },
            {
              key: '-- order_no --',
              value: dispatchNote.order_no || 'N/A',
            },
            {
              key: '-- created_at --',
              // eslint-disable-next-line no-underscore-dangle
              value: this.__dateTimeFormatter(dispatchNote.created_at),
            },
            {
              key: '-- city.postal_code --',
              value: dispatchNote.city.postal_code || 'N/A',
            },
            {
              key: '-- weight --',
              value: dispatchNote.weight || 'N/A',
            },
            {
              key: '-- description --',
              value: dispatchNote.description ? dispatchNote.description.substring(0, 55) : 'N/A',
            },
            {
              key: '-- city.name --',
              value: dispatchNote.city.name || 'N/A',
            },
            {
              key: '-- cod --',
              value: dispatchNote.cod || 'N/A',
            },
            {
              key: '-- delivery_charge --',
              value: dispatchNote.delivery_charge || 'N/A',
            },
          ]
          mappers.forEach(mapper => {
            newTable.innerHTML = newTable.innerHTML.replaceAll(mapper.key, mapper.value)
          })
          newTable.innerHTML = newTable.innerHTML.replaceAll('customComponent', '')
          if (this.waybillLayout.includes('barcode_CF000000')) {
            const barcodeStyle = document.getElementById('barcode_CF000000').style.cssText
            new Vue({
              ...Barcode,
              propsData: {
                id: dispatchNote.waybill_number,
              },
            }).$mount('#barcode_CF000000')
            document.getElementById(`barcode_${dispatchNote.waybill_number}`).style.cssText = barcodeStyle
          }
          if (this.waybillLayout.includes('qrcode_CF000000')) {
            const qrStyle = document.getElementById('qrcode_CF000000').style.cssText
            new Vue({
              ...QRcode,
              propsData: {
                id: dispatchNote.waybill_number,
              },
            }).$mount('#qrcode_CF000000')
            document.getElementById(`qrcode_${dispatchNote.waybill_number}`).style.cssText = qrStyle
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import "../../@core/scss/base/pages/app-invoice.scss";

@page {
    size: A4 portrait;
    margin: 10mm;
  }

.dn-business-name {
  padding-left: 15px;
  font-size: 17px;
  font-weight: bolder;
}
.dn-business-detail {
  display: inline-block;
  padding-left: 15px;
  font-size: 13px;
}
.dn-brand-logo {
  display: block;
  width: 90px;
  height: 90px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;
  border-radius: 20px;
}
.dn-border-top {
  border-top: solid black 2px;
}
.dn-border-right {
  border-right: solid black 2px;
}
.dn-border-bottom {
  border-bottom: solid black 2px;
}
.dn-border-left {
  border-left: solid black 2px;
}
.no-borders {
  border: solid rgba(0, 0, 0, 0) 0px !important;
}
.dn-waybill {
  padding: 10px 5px 5px 0;
  font-weight: bolder;
  color: black;
  text-align: right;
  font-size: 15px;
}
.dn-sub-header {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
  color: black;
}
.dn-city-cod {
  padding: 5px 0 0 5px;
  font-size: 18px;
  font-weight: bolder;
  color: black;
  text-align: center;
}
.dn-content-header {
  padding: 3px 0 0 5px;
  font-size:13px;
  font-weight: bolder;
  margin-bottom: 2px;
}
.dn-content {
  padding-left: 5px;
  font-size: 13px;
  display:inline-block;
}
.dn-valign {
  vertical-align: top;
}
.invoice-preview-col {
  overflow-y: auto;
}
.table-border {
  border: solid black 2px;
  margin-bottom:15px;
  margin-top:10px
}
table {
    table-layout:auto !important;
}
.curfox-label {
    padding: 2px;
    text-align: center;
    font-weight: bolder;
}
.black-components,
.dn-business-name,
.dn-business-detail,
.dn-waybill,
.dn-sub-header,
.dn-city-cod,
.dn-content-header,
.dn-content,
.dn-valign,
.curfox-label {
  font-family: "Arial", sans-serif;
  color: black;
}

@media print {
  // Global Styles
  .col-sm-6 {
    width: 25mm;
  }
  body {
    width: 210mm;
    height: 296mm;
    overflow:hidden;
  }
  .invoiceTable {
    outline: solid black 3px !important;
    border: none !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content-header {
    display: none;
  }
  .content.app-content {
    margin: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
    overflow:hidden;
  }
  .customizer-toggle {
    display: none !important;
  }
  // Invoice Specific Styles
  .invoice-preview-wrapper {
    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .invoice-preview {
    display: block;
  }
  .invoice {
    position: relative;
    display: block;
  }
  .invoice-preview-col {
    display: block;
  }
  .invoice-preview-card {
    display: block;
    break-inside: avoid;
    page-break-inside: avoid;
    break-after: always;
    page-break-after: always;
  }
  section span, section p, section div {
    font-weight: 600 !important;
  }
  .black-components,
  .dn-business-name,
  .dn-business-detail,
  .dn-waybill,
  .dn-sub-header,
  .dn-city-cod,
  .dn-content-header,
  .dn-content,
  .dn-valign,
  .curfox-label {
    font-family: "Arial", sans-serif;
    color: black;
  }
}
</style>
