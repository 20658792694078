<template>
  <div
    :id="'qrcode_'+id"
    width="width"
    height="height"
  />
</template>

<script>
import { qrcode } from '@core/utils/utils'

export default {
  props: {
    id: {
      type: String,
      default: () => '',
    },
    width: {
      type: String,
      default: () => '250px',
    },
    height: {
      type: String,
      default: () => '250px',
    },
  },
  mounted() {
    this.codeGenerator(this.id)
  },
  methods: {
    async codeGenerator(waybill) {
      qrcode(`qrcode_${waybill}`, waybill)
    },
  },
}
</script>
